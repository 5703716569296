export {}

window.onerror = function (e, source, lineno, colno, error) { 
    console.log(source)
    console.log(error)
    console.log(e)
    console.trace();

    // this is an exception for the JODIT Editor as this is a library issue (Still open on their issues )
    if(e == "Uncaught TypeError: Node element must be in editor") {
        return true;
    }

    // This error means that ResizeObserver was not able to deliver all observations 
    // within a single animation frame. It is benign (your site will not break).
    // One of the specification authors - Aleksandar Totic
    // https://stackoverflow.com/a/50387233
    if (e == "ResizeObserver loop limit exceeded") {
        return true;
    }

    if(e == "Uncaught HierarchyRequestError: Failed to execute 'insertNode' on 'Range': The new child element contains the parent."){
        return true;
    }

    const errorContainer = document.getElementById("error-container");
    const root = document.getElementById("root");    
    const errorImage = document.createElement("img");
    const errorMessage = document.createElement("h3");
    const refreshButton = document.createElement("button");

    errorMessage.innerText = 'An error has occurred, please refresh the page or contact support by clicking the Request Support button above.';
    errorMessage.style.textAlign = 'center';
    errorMessage.style.color = '#0078d4';

    errorImage.src = "/ui/public/PM-WhiteFlag-error.svg"
    errorImage.alt = "PoliteMail Error Image"
    errorImage.classList.add("error-image")

    refreshButton.innerHTML = "Dismiss";
    refreshButton.className = "refresh-button";
    refreshButton.onclick = () => {
        errorContainer.style.display = 'none';
    
        root.style.display = '';
    }
    
    errorContainer.appendChild(errorImage)
    errorContainer.appendChild(errorMessage);
    errorContainer.appendChild(refreshButton);
    errorContainer.style.display = 'flex';
    
    root.style.display = 'none';
    
    return true; // prevents browser error messages  
};
